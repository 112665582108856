import Cookies from "js-cookie";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import { lazy, Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import WhatsAppHotline from "./components/HotlineButton.js";
import { saveToken } from "./redux/userSlice.js";
import Loading from "./components/Loading.js";
const Login = lazy(() => import("./pages/Login.js"));
const Verify = lazy(() => import("./pages/Verify.js"));
const Register = lazy(() => import("./pages/Register.js"));
const Home = lazy(() => import("./pages/Home.js"));
const Upload = lazy(() => import("./pages/Upload.js"));

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  //   const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = Cookies.get("user-token");
    if (token) dispatch(saveToken(token));
  }, []);

  return (
    <div className="App">
      <WhatsAppHotline />
      <BrowserRouter basename="/welcome-new-user">
        <Suspense fallback={<Loading />}>
          <Routes>
            {!isAuthenticated ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="/register" element={<Register />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            ) : (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/upload" element={<Upload />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </>
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
